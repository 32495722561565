import React from "react"
import { Box, Image } from "@chakra-ui/core"
import Img from "gatsby-image"
import SEO from "../../components/SEO"
import { useStaticQuery, graphql } from "gatsby"

const Report = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "compbanner.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Box>
      <SEO title="Report" />
      <Box maxW="800px" mx="auto">
        <Img fluid={data.banner.childImageSharp.fluid} />
      </Box>
    </Box>
  )
}

export default Report
